






















import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { AccountInfo } from "@azure/msal-browser";

import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import ChatSection from '@/components/Assistant/ChatSection.vue';

@Component({
  components: {
    ChatSection
  },
  metaInfo() {
    return {
      title: this.$i18n.t("chat.title").toString(),
      meta: [
        { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("chat.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("chat.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Chat extends Vue {   
    // Fields

    // Getter:
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }
    // Watchers & Event Handlers:

    // Component Lifecycle
    public mounted(): void {
        // Initialize some stuff here:
        // this.$store.dispatch("updateMasterData");
    }

    // Methods

}
